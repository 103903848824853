import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "@environments/environment";
import {HttpResponse} from "@shared/infrastructure/http/HttpResponse";
import {firstValueFrom, map} from "rxjs";
import {HttpUtils} from "@shared/infrastructure/http/HttpUtils";
import {Criteria, Filter, FilterGroup, Filters, Operators, Orders} from "@zertifier/criteria";
import {User} from "@features/users/domain/User";
import encode from "@shared/domain/criteria/encode";

interface UserApiDTO {
  id: string,
  username: string,
  firstname: string,
  lastname: string,
  phoneNumber: string,
  email: string,
  created_at: string,
  updated_at: string,
  role: string
}

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  constructor(private httpClient: HttpClient) {
  }

  datatables(parameters: any) {
    return this.httpClient
      .post<HttpResponse<any>>(`${environment.api_url}/users/datatables`, parameters)
      .pipe(map((response) => response.data));
  }

  async getMyData(): Promise<User> {
    const request = this.httpClient.get<HttpResponse<UserApiDTO>>(`${environment.api_url}/users/me`)
      .pipe(map(r => dtoToUser(r.data)));
    return await firstValueFrom(request);
  }

  get(criteria: Criteria): Promise<{ total: number, items: User[] }> {
    const request = this.httpClient
      .get<HttpResponse<{ total: number, items: UserApiDTO[] }>>(
        `${environment.api_url}/users?${HttpUtils.convertCriteriaToQueryParams(criteria)}`,
      )
      .pipe(map((response) => {
        return {
          total: response.data.total,
          items: response.data.items.map(dtoToUser)
        }
      }));

    return firstValueFrom(request);
  }

  async getById(id: string): Promise<User | undefined> {
    const response = await this.get(new Criteria({
      filters: Filters.create(FilterGroup.create(
        Filter.create('id', Operators.EQUAL, id)
      )),
      orders: Orders.EMPTY()
    }));

    if (response.items.length === 0) {
      return;
    }

    return response.items[0]
  }

  save(user: User & { password: string }): Promise<User> {
    const request = this.httpClient
      .post<HttpResponse<UserApiDTO>>(`${environment.api_url}/users/`, {
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        role: user.role,
        phone_number: user.phoneNumber
      })
      .pipe(map((response) => dtoToUser(response.data)));
    return firstValueFrom(request);
  }

  async update(
    user: User & { password: string }
  ): Promise<void> {
    const request = this.httpClient
      .put<HttpResponse<void>>(`${environment.api_url}/users/${user.id}`, {
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        role: user.role,
        phone_number: user.phoneNumber
      });

    await firstValueFrom(request);
  }

  async deleteUser(criteria: Criteria): Promise<void> {
    const params = new HttpParams().set('criteria', encode(criteria))
    const request = this.httpClient.delete<void>(
      `${environment.api_url}/users`,
      {params}
    );

    await firstValueFrom(request);
  }
}

function dtoToUser(dto: UserApiDTO): User {
  return {
    role: dto.role,
    id: dto.id,
    phoneNumber: dto.phoneNumber,
    created_at: new Date(dto.created_at),
    email: dto.email,
    updated_at: new Date(dto.updated_at),
    username: dto.username,
    firstname: dto.firstname,
    lastname: dto.lastname
  }
}
